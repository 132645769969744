body {
    font-weight: 500;
    font-family: "Open Sans", Arial, sans-serif;
}
.agent-info-container {
    background-color: #002463;
    color: #fff;
    font-family: "Open Sans", Arial, sans-serif;

    border-bottom: solid;
    border-bottom-width: 10px;
    border-bottom-color: #967126;
    box-shadow: 0 12px 18px -6px rgba(0,0,0,0.3);


    width: 100%;
}

.agent-info-container.bottom {
    bottom:0;
    position: relative;
}

.agent-info-container .name {
    font-weight: 700;
    font-size: 2.125em;
}
.agent-url-container {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width:100vw;
    background-color: #967126;
    padding: 0.5em 1em;
    border-radius: 0.5em;
}

.agent-info-container .agent-url {
    color: white;
    font-size: 0.9em;
    cursor: pointer;
    text-overflow: ellipsis;
}

.agent-info-container .agent-contact-icon {
    padding-right: 1em;
    font-size: 1.25em;
    cursor: pointer;
}
.agent-info-container .agent-contact {
    font-size: 0.875em;
    cursor: pointer;
}

.agent-info-container .text-bottom-border {
    border-bottom: solid;
    border-bottom-width: 1px;
    border-bottom-color: rgba(255,255,255,0.35);
    padding-bottom: 10px;
}
.agent-info-container .agent-url-container {
}

.agent-info-container .description {
    font-size: 0.6875em;
    line-height: 1.6em;
    padding-bottom: 1.5em
}

.agent-info-container img.accreditation {
    padding-bottom: 2em;
}

@media (max-width: 768px) {
    .agent-info-container img.accreditation {
        max-width: 50%;
    }
}

@media (max-width: 576px) {
    .agent-info-container img.accreditation {
        max-width: 70%;
    }
}


img.profile-photo {
    width: 150px;
    height: 150px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
}
img.profile-photo.shrink-1 {
    width: 100px;
    height: 100px;
}
img.profile-photo.shrink-2 {
    width: 50px;
    height: 50px;
}
img.profile-photo.shrink-3 {
    width: 32px;
    height: 32px;
}
img.profile-photo {
    display: inline;
    margin: 0 auto;
    border-radius: 100%;
    object-fit: cover;
}

.color-highlight {
    color: #967126;
}

.sales-profile-modal {
    color: #666;
}
.sales-profile-modal.modal-lg {
    max-width: 720px;
}
.email-modal-header {
    background-color: #002463;
    color: #fff;
    box-shadow: 0 12px 18px -6px rgba(0,0,0,0.3);
    border-bottom-width: 0;
}
.sales-profile-modal h1 {
    color: #967126;
    font-weight: 700;
}

body.modal-open {
    overflow: hidden;
}
.modal-content {
    border: none;
    border-radius: 0;
}
.modal-header {
    border-radius: 0;
}
.modal-header .btn-close.align-top {
    margin: -0.5rem -0.5rem auto auto;
}
.modal-backdrop {
    backdrop-filter: saturate(180%) blur(5px);
    -webkit-backdrop-filter: saturate(180%) blur(5px);
    background: rgba(0,0,0,.55);
    transition: -webkit-backdrop-filter 1s 1s,backdrop-filter 1s 1s;
}
.modal-backdrop.show {
    opacity: 1;
}
.form-control {
    border-radius: 0;
    border-bottom-color: rgb(238, 238, 238);
    border-left-color: rgb(238, 238, 238);
    border-right-color: rgb(238, 238, 238);
    border-top-color: rgb(238, 238, 238);
    line-height: 1.8em;

    padding-left: 1.5em;
    padding-right: 1.5em;
}

.btn {
    border-radius: 0;
    border: none;
    color: #000;
    padding-left: 1.5em;
    padding-right: 1.5em;
}
.btn-primary {
    background-color: #967126;
    color: white;
}
.btn-primary:hover,.btn-primary:focus {
    background-color: #e1a600;;
}
.btn-primary.disabled {
    background-color: #967126;
    color: #ccc;
}



.floating-inquiry-btn {
    position: fixed;
    padding: 1em;
    right: 1.5em;
    bottom: 4em;
    background-color: #967126;
    color: white;

    border-radius: 100px;
    font-size: 0.75em;

    cursor: pointer;
    box-shadow: 0 12px 18px -6px rgba(0,0,0,0.3);
}

.property-list {
    color: #666;
}

.property-carousel .carousel-indicators [data-bs-target] {
    width: 8px;
    height: 8px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 100%;
}
.property-carousel {
    border-width: 10px;
    border-color: #fff;
    border-style: solid;
    box-shadow: 0 12px 18px -6px rgba(0,0,0,0.3);
    margin-bottom: 2em;
}

.property-logo {
    border-width: 10px;
    border-style: solid;
    border-color: rgba(205,205,205,0.5);
}
.property-action-icon {
    color: #0169a6;
    font-size: 2em;
    margin-right: 0.25em;
    cursor: pointer;
}
.property-action-text {
    font-size: 0.75em;
    cursor: pointer;
}

.elegant-icons {
    font-family: ElegantIcons;
}

.carousel-modal .modal-body {
    padding: 0;
}

.carousel-modal-backdrop {
    background-color: rgb(11, 11, 11);
}
.carousel-modal-backdrop.show {
    opacity: 0.8;
    background-color: rgb(11, 11, 11);
}
.carousel-modal .carousel-control-prev, .carousel-modal .carousel-control-prev.show {
    position: fixed;
    left: 0;
    width: 5%;
}
.carousel-modal .carousel-control-next, .carousel-modal .carousel-control-next.show {
    position: fixed;
    right: 0;
    width: 5%;
}
.carousel-modal .carousel-control-prev-icon, .carousel-modal .carousel-control-next-icon{
    width: 3rem;
    height: 3rem;
}
.carousel-modal .modal-content {
    background-color: rgba(0,0,0,0);
    color: #f3f3f3;
}
.carousel-modal .active-item-info-row {
    height: 2em;
}
.carousel-modal .active-item-info-row .page-info {
    font-size: 0.75em;
    color: #ccc;
}
.carousel-modal .close-btn {
    color: #fff;
    opacity: 0.5;
}

.site-visit.react-calendar {
    width: 100%;
}
.site-visit .react-calendar__tile {
    height: 4em;
}

.site-visit .react-calendar__tile--active, .site-visit .react-calendar__tile--active:enabled, .site-visit .react-calendar__tile--active:enabled:hover {
    /*background-color: #f8c301;*/
    background-color: #967126;
    color: white;
}
.site-visit .react-calendar__tile--now {
    background-color: #fff;
}

.custom-react-select {
    color: #ccc;
    background-color: yellow;
}


.active-group-info {
    color: #666;
    line-height: 2em;
}

.property-list h1 {
    font-size: 2.5em;
    font-weight: 400;
}
@media (max-width: 500px) {
    .property-list h1 {
        font-size: 2em;
        font-weight: 400;
    }
}

.property-list .header-search-input {
    font-size: 0.8em;
}

.scroll-to-top {
    position: fixed;
    right: 0;
    transition: all .15s linear;
    transition-property: opacity, right;
    width: 40px;
    height: 40px;
    bottom: 125px;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: rgba(0, 0, 0, 0.4);
}
.scroll-to-top:not(.show) {
    right: -40px;
    opacity: 0;
}

#active-group-mission-img {
    /*transition: margin-top .15s linear;*/
}

.active-group-info-text-container {
    padding: 20px 30px 30px;
    box-shadow: 0px 12px 18px -6px rgb(0 0 0 / 10%);
}


.form-control.error:not(:focus),.react-select.error:not(:focus) {
    border-color: #ff6464;
    border-style: solid;
    border-width: 1px;
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;

    /* When the animation is finished, start again */
    animation-iteration-count: 1;
}

@-webkit-keyframes shake {
    0% {
        -webkit-transform: translateX(0);
    }

    20% {
        -webkit-transform: translateX(-5px);
    }

    40% {
        -webkit-transform: translateX(5px);
    }

    60% {
        -webkit-transform: translateX(-5px);
    }

    80% {
        -webkit-transform: translateX(5px);
    }

    100% {
        -webkit-transform: translateX(0);
    }
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }

    20% {
        transform: translateX(-5px);
    }

    40% {
        transform: translateX(5px);
    }

    60% {
        transform: translateX(-5px);
    }

    80% {
        transform: translateX(5px);
    }

    100% {
        transform: translateX(0);
    }
}

.shake {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;

    /* When the animation is finished, start again */
    animation-iteration-count: 1;
}


.min-vh-50 {
    min-height: 50vh;
}


.activegroup-footer {
    background-color: #002463;
    color: #fff;
}

.btn-text {
    color: white;
    margin: 0;
    padding: 0;
    font-size: 0.875em;
}
.btn-text:focus {
    color: white;
}
.btn-text:hover {
    color: #cccccc;
}

.pointer-cursor {
    cursor: pointer;
}
